<template>
  <!--    编辑-->
  <el-dialog :title="formTitle" :visible.sync="messageBox" width="50vw" class="addAlameBox" :close-on-click-modal="true"
    :destroy-on-close="true" @close="handleCancel" :before-close="cancel">
    <div class="dialog_cont">
      <div class="form_box">
        <FormBox ref="formRef" :showFlag="false" :formArr="formArr" :record="addForm" labelWidth="6vw">
        </FormBox>
      </div>

      <div class="map">
        <Mapc ref="map" :location="location" />
      </div>

    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Mapc from "./map";
import FormBox from "@/components/lxw/Form/index.vue";
export default {
  components: { FormBox, Mapc },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    openType: {
      type: String,
      default: "add",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentMess: { // 父元素信息
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        // "pid": 0, //上级冷库ID,大库为0
        // "level": 0, //仓库等级(1-大库、2-货架、3-单元)，数据字典
        // "location": "", //经纬度

        // "name": "", //冷库名称
        // "capacity": 0.0, //仓库总容量
        // "capacityUnit": "", //容量单位（只可修改大库）
        // "capacityUsed": 0.0, //已使用容量
        // "capacityUnused": 0.0, //剩余容量
        // "type": "", //仓库类型（高档、低档、常温）,数据字典
        // "address": "", //地址
        // "lxfs": "", //联系方式
        // "lxr": "", //联系人
        // "remark": "", //备注，最多500字
        {
          label: "冷库名称",
          key: "name",
          type: "Input",
        },
        {
          label: "仓库总容量",
          key: "capacity",
          type: "InputNumber",
        },
        {
          label: "容量单位",
          key: "capacityUnit",
          type: "Input",
        },
        {
          label: "已使用容量",
          key: "capacityUsed",
          type: "InputNumber",
        },
        {
          label: "剩余容量",
          key: "capacityUnused",
          type: "InputNumber",
        },
        {
          label: "仓库类型",
          key: "type",
          type: "Select",
          options: [
            {
              label: "高档",
              value: "高档",
            },
            {
              label: "低档",
              value: "低档",
            },
            {
              label: "常温",
              value: "常温",
            },
          ],
        },
        {
          label: "地址",
          key: "address",
          type: "Input",
        },
        {
          label: "联系方式",
          key: "lxfs",
          type: "Input",
        },
        {
          label: "联系人",
          key: "lxr",
          type: "Input",
        },
        {
          label: "备注",
          key: "remark",
          type: "Textarea",
        },
      ],
      addForm: {
        name: '',
        capacity: "", //仓库总容量
        capacityUnit: "", //容量单位（只可修改大库）
        capacityUsed: "", //已使用容量
        capacityUnused: "", //剩余容量
        type: "", //仓库类型（高档、低档、常温）,数据字典
        address: "", //地址
        lxfs: "", //联系方式
        lxr: "", //联系人
        remark: "", //备注，最多500字
      },
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      params: {},
      location: null,
    };
  },
  watch: {
    messageBox: {
      handler(newVal, oldVal) {

        this.$nextTick(() => {
          this.init();
        })
      },
      deep: true,
    },
  },
  mounted() {
    // console.log(this.record, "record");
    // this.location = null
    // this.init();
  },
  methods: {
    init() {
      let index = this.formArr.findIndex(item => item.key == 'capacityUnit');
      let typeIndex = this.formArr.findIndex(item => item.key == 'type');
      if (this.openType == 'add') {
        this.addForm = {}
        // this.resetForm();        
        // 新增
        if (this.parentMess && this.parentMess.id) { // 新增子集
          let level = null
          if (this.parentMess.level == 1) {
            level = 2
          } else if (this.parentMess.level == 2) {
            level = 3
          } else {
            level = null
          }
          if (!level) {
            this.$message.error("层级错误请确认");
            return;
          }
          this.params = {
            pid: this.parentMess.id, //上级冷库ID,大库为0
            level, // 仓库等级(1-大库、2-货架、3-单元)，数据字典
          }

          this.formArr[index].disabled = true
          this.addForm.capacityUnit = this.parentMess.capacityUnit
          this.formArr[typeIndex].disabled = true
          this.addForm.type = this.parentMess.type

        } else {
          this.formArr[index].disabled = false
          this.formArr[typeIndex].disabled = false
          this.params = {
            pid: 0, //上级冷库ID,大库为0
            level: 1, //仓库等级(1-大库、2-货架、3-单元)，数据字典
          }
        }
      } else { // 修改
        this.addForm = this.record;
        if (this.addForm.pid == 0) { // 修改的大库
          this.formArr[index].disabled = false
          this.formArr[typeIndex].disabled = false
        } else {
          this.formArr[index].disabled = true
          this.formArr[typeIndex].disabled = true
        }
        if (this.record.location) {
          this.location = this.record.location
        } else {
          this.location = null
        }
      }
    },

    handelSure() {
      this.longitudeLatitude.push(this.$refs.map.longitudeLatitude);
      // this.$set(this.addForm,'dthzslld',this.longitudeLatitude.join('|'))
    },
    handelFalse() {
      this.longitudeLatitude = [];
    },

    // 确认提交
    saveForm() {

      // console.log(this.$refs.formRef)
      // console.log(this.addForm)
      // console.log(this.params)
      //  console.log(this.$refs.map.longitudeLatitude.join(','))

      let param = {
        ...this.params,
        ...this.addForm,
        location: this.$refs.map.longitudeLatitude[1] + ',' + this.$refs.map.longitudeLatitude[0]
      }
      //   "pid": 0, //上级冷库ID,大库为0
      // "name": "", //冷库名称
      // "level": 0, //仓库等级(1-大库、2-货架、3-单元)，数据字典
      // "capacity": 0.0, //仓库总容量
      // "capacityUnit": "", //容量单位（只可修改大库）
      // "capacityUsed": 0.0, //已使用容量
      // "capacityUnused": 0.0, //剩余容量
      // "type": "", //仓库类型（高档、低档、常温）,数据字典
      // "location": "", //经纬度
      // "address": "", //地址
      // "lxfs": "", //联系方式
      // "lxr": "", //联系人
      // "remark": "", //备注，最多500字

      let url = "/pro09/lengku/save";

      if (this.openType == 'add') {

      }
      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },
    cancel() {
      this.$refs["formRef"].resetForm();
      this.$emit("handleClose");
    },

    handleCancel() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.dialog_cont {
  max-height: 50vh;
  overflow: auto;
}

.addAlameBox ::v-deep .dialog_cont .form_box .el-form {
  max-height: 100vh !important;
  /* min-height: 650px !important; */
}

.addAlaForm {
  max-height: 100vh !important;
}

.map {
  width: 100%;
  height: 350px;
  margin: 10px 0;
  /* border: 1px solid #f00; */
  box-sizing: border-box;
}
</style>
<template>
  <div style="width: 100%; height: 100%; box-sizing: border-box">
    <div
      id="mapContainer"
      style="width: 100%; height: 100%; box-sizing: border-box"
    ></div>
  </div>
</template>
<script>
export default {
  name: "MapComponent",
  props: {
    location: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      map: null,
      lnglat: [],
      markers: [],
      position: {},
      longitudeLatitude: [116.397428, 39.90923],
      dataForm: {},
    };
  },
  watch: {
    location: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.initMap();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // 地图初始化
    initMap() {
      let centerLen = [116.397428, 39.90923];
      if (this.location) {
        centerLen = [this.location.split(",")[1], this.location.split(",")[0]] ;
      }
      this.map = new AMap.Map("mapContainer", {
        // 设置地图容器id
        zoom: 10, // 初始化地图级别
        center: centerLen, //中心点坐标
        resizeEnable: true,
      });
      // this.map.setMapStyle('amap://styles/dark');
      this.setMarker(centerLen);

      // 监听鼠标点击事件
      this.map.on("click", this.clickMapHandler);
    },
    // 点击地图事件获取经纬度，并添加标记
    clickMapHandler(e) {
      this.dataForm.kqLongitude = e.lnglat.lng;
      this.dataForm.kqLatitude = e.lnglat.lat;
      this.lnglat = [e.lnglat.lng, e.lnglat.lat];
      this.setMarker(this.lnglat);
      const that = this;
      this.longitudeLatitude = [];
      this.position = {
        // 经度
        longitude: e.lnglat.lng,
        // 纬度
        latitude: e.lnglat.lat,
        address: that.address,
      };
      this.longitudeLatitude.push(this.position.latitude);
      this.longitudeLatitude.push(this.position.longitude);
    },
    //  添加标记
    setMarker(lnglat) {
      this.removeMarker();
      let marker = new AMap.Marker({
        position: lnglat,
      });
      marker.setMap(this.map);
      this.markers.push(marker);
    },
    // 删除之前后的标记点
    removeMarker() {
      if (this.markers) {
        this.map.remove(this.markers);
      }
    },
  },
};
</script>